<template>
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-button type="primary" size="mini" @click="add">添加</el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="userList"
            border>
            <el-table-column  prop="id" label="Id"></el-table-column>
            <el-table-column  prop="role_key" label="角色标识"></el-table-column>
            <el-table-column  prop="name" label="角色名称"></el-table-column>
            <el-table-column prop="status" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="alter(scope.row.id,scope.row.role_key,scope.row.name)"> 修改 </el-button>
                    <el-button type="warning" size="mini" @click="setMenu(scope.row.id)"> 设置菜单 </el-button>
                    <el-button type="warning" size="mini" @click="setApi(scope.row.id)"> 设置权限 </el-button>
                </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 添加/修改账号弹窗 -->
<el-dialog title="添加/修改角色" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="角色标识">
            <el-input v-model="role_key" placeholder="请输入角色标识"></el-input>
        </el-form-item>
        <el-form-item label="角色名称">
            <el-input v-model="name" placeholder="请输入角色名称"></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
    </div>
</el-dialog>
<!-- 菜单 -->
<el-dialog title="设置菜单" :visible.sync="menuDialog" :close-on-click-modal=false>
    <el-tree :data="menuList" show-checkbox default-expand-all node-key="id" ref="menuTree" highlight-current :props="defaultProps">
    </el-tree>
    <div slot="footer" class="dialog-footer">
        <el-button @click="menuDialog = false">取 消</el-button>
        <el-button type="primary" @click="getMenu">确 定</el-button>
    </div>
</el-dialog>
<!-- api -->
<el-dialog title="设置Api" :visible.sync="apiDialog" :close-on-click-modal=false>
    <el-tree :data="apiList" show-checkbox default-expand-all node-key="id" ref="apiTree" highlight-current :props="defaultProps">
    </el-tree>
    <div slot="footer" class="dialog-footer">
        <el-button @click="apiDialog = false">取 消</el-button>
        <el-button type="primary" @click="getAuth">确 定</el-button>
    </div>
</el-dialog>

</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    export default {
        name: 'accountJurisdiction',
        components: {},
        data() {
            return {

                total: 0,
                page: 1,
                pagesize: 10,

                loading: true,

                id: '',
                name: '',
                role_key: '',

                dialog: false,
                menuDialog: false,
                apiDialog: false,
                userList: [],

                menuList: [],
                apiList: [],
                role_id: '',

                defaultProps: {
                    children: 'children',
                    label: 'name'
                },

                menu: [],
                api: []
            }
        },
        watch: {},
        mounted() {
            this.getRole()
            this.getAllMenu()
            this.getAllApi()
        },
        filters: {},
        methods: {
            // 添加角色
            onSubmit() {
                if (this.id != '') {
                    api.updataRoleUser({
                        'role_id': this.id,
                        'role_key': this.role_key,
                        'name': this.name,
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.getRole()
                        this.id = ''
                        this.role_key = ''
                        this.name = ''
                        this.dialog = false
                    })
                    return
                }
                api.addRoleUser({
                    'role_key': this.role_key,
                    'name': this.name,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getRole()
                    this.role_key = ''
                    this.name = ''
                    this.dialog = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getRole()
            },
            currentChange(val) {
                this.page = val
                this.getRole()
            },
            // 获取账户列表
            getRole(page, size) {
                api.getRole({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.userList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            // 获取菜单
            getAllMenu() {
                api.getAllMenu(null, res => {
                    console.log(res)
                    this.menuList = res.data
                    let list = []
                    this.menuList.some(item => {
                        if (item.children != undefined) {
                            list.push(item.id)
                            item.children.some(sub => {
                                if (sub.children != undefined) {
                                    list.push(sub.id)
                                }
                            })
                        }
                    })
                    this.menu = list
                })

            },
            // 获取api
            getAllApi() {
                api.getAllApi(null, res => {
                    this.apiList = res.data
                    let list = []
                    this.apiList.some(item => {
                        if (item.children != undefined) {
                            list.push(item.id)
                            item.children.some(sub => {
                                if (sub.children != undefined) {
                                    list.push(sub.id)
                                }
                            })
                        }
                    })
                    this.api = list
                })
            },
            // 修改
            alter(id, key, name) {
                this.id = id
                this.role_key = key
                this.name = name
                this.dialog = true
            },
            // 设置角色菜单窗口
            setMenu(id) {
                this.role_id = id
                    // this.$refs.menuTree.setCheckedKeys([5]);
                api.getRoleAllMenu({
                    role_id: id
                }, res => {
                    this.menuDialog = true
                        // 去重并赋值
                    this.$nextTick(() => {
                        let list = res.data.id_list.filter(item => {
                            return !this.menu.some(sub => (sub == item))
                        })
                        this.$refs.menuTree.setCheckedKeys(list);
                    })

                })

            },
            // 设置角色权限窗口
            setApi(id) {
                this.role_id = id
                api.getRoleAllAuth({
                    role_id: id
                }, res => {
                    this.apiDialog = true
                    this.$nextTick(() => {
                        let list = res.data.id_list.filter(item => {
                            return !this.api.some(sub => (sub == item))
                        })
                        console.log(list)
                        this.$refs.apiTree.setCheckedKeys(list);
                    })
                })

            },
            // 设置角色菜单
            getMenu() {
                let menu_id = this.$refs.menuTree.getCheckedKeys()
                api.getMenu({
                    role_id: this.role_id,
                    menu_ids: menu_id
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.menuDialog = false
                })
            },
            // 设置角色权限
            getAuth() {
                let api_id = this.$refs.apiTree.getCheckedKeys()
                api.getAuth({
                    role_id: this.role_id,
                    api_ids: api_id
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.apiDialog = false
                })
            },
            add() {
                this.id = ''
                this.role_key = ''
                this.name = ''
                this.dialog = true
            }
        }

    }
</script>
<style scoped>
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
</style>